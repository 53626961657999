"use client";
import {
  UilAngleDown,
  UilBars,
  UilDollarSign,
  UilPresentationPlay,
} from "@iconscout/react-unicons";
import { CloseButton, Divider, Drawer, Menu } from "@mantine/core";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { FC, MouseEvent, useCallback, useEffect, useState } from "react";

import { useFirebaseAuthCheck } from "../hooks/useFirebaseAuth";
import { useIsMobile } from "../hooks/useIsMobile";
import { useColorSchemeContext } from "../providers/ColorSchemeProvider";
import FrecLogo from "../public/webstatic/logo/frec.svg";
import { classNames } from "../utils/classNames";
import { BrokerageWebPath, FrecWebPath } from "../utils/paths";
import { windowIfAvailable } from "../utils/windowIfAvailable";
import { AnimatedIcon, Button } from "./Button";
import { FullLinkProps } from "./Link";
import { UnderlineLink } from "./UnderlineLink";

const TOP_SCROLL_BUFFER = 20;

const LandingLinks = [
  { href: FrecWebPath.pricing, label: "Pricing" },
  {
    href: BrokerageWebPath.demo,
    label: "Live demo",
    external: true,
  },
  {
    href: FrecWebPath.taxLossHarvesting,
    label: "Tax savings",
  },
  { href: FrecWebPath.resources, label: "Resources" },
  { href: FrecWebPath.about, label: "About us" },
];

const BrokerageLinks = [
  { href: BrokerageWebPath.login, label: "Log in" },
  { href: BrokerageWebPath.signup, label: "Sign up" },
];

export const Header = ({
  className,
  isKnownUser,
  showBorder = true,
  showLinks = true,
  notFixed,
}: {
  className?: string;
  isKnownUser: boolean;
  showBorder?: boolean;
  showLinks?: boolean;
  notFixed?: boolean;
}) => {
  const isMobile = useIsMobile();
  const [isScrollTop, setScrollTop] = useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const { isLoggedIn, logOut } = useFirebaseAuthCheck();

  const listenScrollEvent = useCallback(() => {
    if (!isScrollTop && (windowIfAvailable?.scrollY ?? 0) < TOP_SCROLL_BUFFER) {
      return setScrollTop(true);
    } else if (
      isScrollTop &&
      (windowIfAvailable?.scrollY ?? 0) > TOP_SCROLL_BUFFER
    ) {
      return setScrollTop(false);
    }
  }, [isScrollTop]);

  useEffect(() => {
    windowIfAvailable?.addEventListener("scroll", listenScrollEvent, true);
    return () =>
      windowIfAvailable?.removeEventListener("scroll", listenScrollEvent);
  }, [listenScrollEvent]);

  const { headerTheme } = useColorSchemeContext();
  const currentPathname = usePathname();

  const handleDrawerLinkClick = useCallback(() => {
    setMobileMenuOpen(false);
  }, []);

  const drawerLinkStyles =
    "w-full text-sm font-medium cursor-pointer text-frecBlack link focus:bg-frecXLightGray";
  const dividerStyles = "my-2 !border-frecLightGray";

  return (
    <div className={classNames(headerTheme, className)}>
      <header
        className={classNames(
          "z-50 flex w-full transform-gpu justify-center transition duration-100",
          notFixed ? "" : "fixed",
          isScrollTop
            ? "bg-transparent"
            : "bg-frecBeige/80 dark:bg-frecBlack/80 backdrop-blur-sm",
          showBorder
            ? isScrollTop
              ? "dotted-h border-b"
              : "border-frecDarkBeige border-b"
            : "",
        )}
      >
        <div
          className={classNames(
            "flex w-full max-w-7xl items-center justify-between py-4 lg:mx-12",
          )}
        >
          <nav className="dark:text-frecWhite flex w-full max-w-7xl flex-wrap items-center justify-between px-6 xl:px-16">
            <Link
              href={FrecWebPath.root}
              className="mr-1 flex h-[42px] w-[77px] shrink-0 cursor-pointer items-center xl:mr-20"
            >
              <FrecLogo
                className="h-full w-full"
                aria-label="Frec"
                src="/webstatic/logo/frec.svg"
              />
            </Link>
            {showLinks ? (
              <>
                {/* Centered links */}
                <div
                  className={classNames(
                    "hidden flex-row gap-3",
                    isKnownUser ? "lg:flex" : "md:flex",
                  )}
                >
                  <UnderlineLink
                    href={FrecWebPath.pricing}
                    active={!!currentPathname?.startsWith(FrecWebPath.pricing)}
                    className={classNames("text-frecBlack dark:text-frecWhite")}
                  >
                    Pricing
                  </UnderlineLink>

                  <Menu
                    trigger="hover"
                    opened={dropdownOpened}
                    onChange={setDropdownOpened}
                    openDelay={100}
                    closeDelay={400}
                    width={276}
                    shadow="md"
                    classNames={{
                      dropdown: "bg-frecBeige rounded-lg py-2 px-0",
                      item: "hover:bg-frecDarkBeige px-6 py-4 rounded-none",
                      itemLabel: "flex items-center gap-4 font-medium",
                    }}
                  >
                    <Menu.Target>
                      <div className="flex cursor-pointer items-center gap-2 px-6 font-medium">
                        How it works
                        <UilAngleDown
                          size={20}
                          className={classNames(
                            "transition",
                            dropdownOpened ? "rotate-180" : "",
                          )}
                        />
                      </div>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Item component={"a"} href={BrokerageWebPath.demo}>
                        <span className="bg-frecBlack flex h-10 w-10 items-center justify-center rounded-lg">
                          <UilPresentationPlay
                            className="text-frecNeon"
                            size={20}
                          />
                        </span>
                        <span className="font-medium">Live demo</span>
                      </Menu.Item>

                      <Menu.Item
                        component={Link}
                        href={FrecWebPath.taxLossHarvesting}
                      >
                        <span className="bg-frecBlack flex h-10 w-10 items-center justify-center rounded-lg">
                          <UilDollarSign className="text-frecNeon" size={20} />
                        </span>
                        <span className="font-medium">Tax savings</span>
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>

                  <UnderlineLink
                    href={FrecWebPath.resources}
                    active={
                      !!currentPathname?.startsWith(FrecWebPath.resources)
                    }
                    className={classNames("text-frecBlack dark:text-frecWhite")}
                  >
                    Resources
                  </UnderlineLink>

                  <UnderlineLink
                    href={FrecWebPath.about}
                    active={!!currentPathname?.startsWith(FrecWebPath.about)}
                    className={classNames("text-frecBlack dark:text-frecWhite")}
                  >
                    About us
                  </UnderlineLink>
                </div>
                {/* Right-aligned links */}
                <div className="flex flex-row items-center gap-4">
                  {isKnownUser ? (
                    <Button
                      className="group flex items-center"
                      onClick={() => {
                        if (windowIfAvailable)
                          windowIfAvailable.location.href =
                            BrokerageWebPath.overview;
                      }}
                      small
                      variant={
                        headerTheme === "dark"
                          ? "neon"
                          : isMobile
                            ? "outline"
                            : "black"
                      }
                    >
                      Go to Overview <AnimatedIcon small />
                    </Button>
                  ) : (
                    <>
                      <UnderlineLink
                        external
                        href={BrokerageWebPath.login}
                        className="text-frecBlack dark:text-frecWhite hidden xl:flex"
                      >
                        Log in
                      </UnderlineLink>
                      <Button
                        className="flex md:hidden xl:flex"
                        onClick={() =>
                          (location.href = BrokerageWebPath.signup)
                        }
                        small
                        variant={
                          headerTheme === "dark"
                            ? "neon"
                            : isMobile && isScrollTop
                              ? "outline"
                              : "black"
                        }
                      >
                        Get started
                      </Button>
                    </>
                  )}
                  {(isMobile || !isKnownUser) && (
                    <button
                      className="-mr-2 block p-2 xl:hidden"
                      title="Menu"
                      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    >
                      <UilBars className="h-5 w-5" />
                    </button>
                  )}
                  <Drawer
                    classNames={{ content: "bg-frecXXLightGray", body: "p-0" }}
                    position="right"
                    size="xs"
                    opened={mobileMenuOpen}
                    onClose={() => setMobileMenuOpen(false)}
                    overlayProps={{ backgroundOpacity: 0.1 }}
                    withCloseButton={false}
                    transitionProps={{ transition: "slide-left" }}
                  >
                    <DrawerCloseButton
                      closeDrawer={() => setMobileMenuOpen(false)}
                    />
                    {isLoggedIn && process.env.NODE_ENV === "development" ? (
                      <>
                        <DrawerLink
                          href="/login"
                          onClick={() => logOut()}
                          className={drawerLinkStyles}
                        >
                          Log out
                        </DrawerLink>
                        <Divider className={dividerStyles} />
                      </>
                    ) : null}
                    {isKnownUser ? null : (
                      <>
                        {BrokerageLinks.map((link) => (
                          <DrawerLink
                            key={link.href}
                            href={link.href}
                            className={drawerLinkStyles}
                            external
                          >
                            {link.label}
                          </DrawerLink>
                        ))}
                        <Divider className={dividerStyles} />
                      </>
                    )}
                    {isKnownUser ? (
                      <DrawerLink
                        href={BrokerageWebPath.overview}
                        className={drawerLinkStyles}
                        external
                      >
                        Overview
                      </DrawerLink>
                    ) : null}
                    {LandingLinks.map((link) => (
                      <DrawerLink
                        key={link.href}
                        href={link.href}
                        onClick={handleDrawerLinkClick}
                        className={drawerLinkStyles}
                        external={link.external}
                      >
                        {link.label}
                      </DrawerLink>
                    ))}
                  </Drawer>
                </div>
              </>
            ) : null}
          </nav>
        </div>
      </header>
    </div>
  );
};

const DrawerLink = (props: FullLinkProps & { external?: boolean }) => {
  const { className, external, onClick, ...rest } = props;
  const openLink = (e: MouseEvent<HTMLAnchorElement>) => {
    onClick?.(e);
    if (windowIfAvailable) {
      windowIfAvailable.location.href = props.href;
    }
    e.preventDefault();
  };
  return (
    <Link
      {...rest}
      onClick={external ? openLink : onClick}
      className={classNames(
        "hover:bg-frecXLightGray decoration-frecBlack flex px-6 py-4",
        className,
      )}
    />
  );
};

export const DrawerCloseButton: FC<{ closeDrawer: () => void }> = ({
  closeDrawer,
}) => {
  return (
    <div className="flex justify-end">
      <CloseButton
        className="text-frecBlack mb-2 mr-6 mt-6"
        iconSize={24}
        size={24}
        onClick={closeDrawer}
      />
    </div>
  );
};
